<template>
  <div>
    <b-row class="my-4">
      <b-col cols="12">
        <h3 class="center text-info float-left text-uppercase">
          {{ $t('sections.stages') }} :: {{ $t('artistsManagement.videos') }} ::
          {{ model.name }}
        </h3>

        <router-link
          :to="{ name: `events-stages-edit`, params: { id: model.id } }"
        >
          <b-button size="sm" class="ml-3" variant="info">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-backspace-fill mr-2"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241
                7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2
                2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147
                2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0
                1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976
                8 5.829 5.854z"
              />
            </svg>
            {{ $t('buttons.back') }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>

    <form @submit.prevent="onSubmit" v-if="loaded">
      <b-row>
        <b-col
          class="ml-3 mb-3 p-4 bg-white border border-secondary rounded-lg"
        >
          <h4>{{ $t('artistsManagement.videos') }}</h4>
          <b-input-group :prepend="$t('inputs.stage')" class="mb-3 mt-3">
            <b-form-input
              v-model="model.name"
              disabled
              maxlength="100"
              type="text"
              required
            />
          </b-input-group>

          <b-input-group :prepend="$t('eventSchedule.date')" class="mb-3">
            <b-form-select
              required
              v-model="form.date"
              :options="daysOptions"
            />
          </b-input-group>

          <b-input-group
            :prepend="$t('Link')"
          >
            <b-form-input
              :placeholder="$t('inputs.url')"
              v-model="form.videoId"
              maxlength="100"
              type="text"
              required
              @blur="loadEmbed"
            />
            <b-input-group-append is-text v-b-tooltip.hover.html="getTooltip()">
              <b-icon icon="exclamation-circle" />
            </b-input-group-append>
          </b-input-group>
          <small v-html="getTooltip()" class="mb-3 d-block text-right"></small>

          <b-form-group :label="$t('inputs.thumb')" class="mb-3">
            <ImageUploader
              minWidth="1155"
              minHeight="780"
              v-model="upload"
            ></ImageUploader>
          </b-form-group>

          <b-input-group :prepend="$t('inputs.language')" class="mb-3">
            <b-form-select
              required
              v-model="form.language"
              :options="languageOpts"
            />
          </b-input-group>

          <b-input-group :prepend="$t('inputs.titleEN')" class="mb-3">
            <b-form-input
              :placeholder="$t('placeholder.nameEN')"
              v-model="form.nameEN"
              maxlength="100"
              type="text"
              required
            />
          </b-input-group>
          <b-input-group :prepend="$t('inputs.titlePT')" class="mb-3">
            <b-form-input
              :placeholder="$t('placeholder.namePT')"
              v-model="form.namePT"
              maxlength="100"
              type="text"
              required
            />
          </b-input-group>

          <TextArea
            :label="$t('inputs.descriptionPT')"
            :placeholder="$t('placeholder.descriptionPT')"
            v-model="form.descriptionPT"
            :enableEnter="true"
            maxlength="1000"
          />

          <TextArea
            :label="$t('inputs.descriptionEN')"
            :placeholder="$t('placeholder.descriptionEN')"
            v-model="form.descriptionEN"
            :enableEnter="true"
            maxlength="1000"
          />

          <!-- HORÁRIOS -->
          <b-row>
            <b-col lg="21" sm="12">
              <div class="mb-3 p-4 bg-white border border-secondary rounded-lg">
                <div
                  class="d-flex justify-content-between align-items-center pb-1"
                >
                  <h4 class="mb-2">{{ $t('inputs.subtitle') }}s</h4>

                  <b-button size="sm" variant="success" @click="onAddSubtitle">
                    {{ $t('buttons.add') }}
                  </b-button>
                </div>

                <b-row>
                  <b-col>
                    <div
                      v-for="sub in form.subtitles"
                      :key="sub.id"
                      class="bg-white rounded"
                    >
                      <b-row>
                        <b-col class="mb-3" lg="5" sm="12">
                          <b-input-group :prepend="$t('inputs.subtitle')">
                            <b-form-input
                              v-model="sub.subtitle"
                              maxlength="200"
                              type="text"
                              required
                            />
                          </b-input-group>
                        </b-col>
                        <b-col class="mb-3" lg="5" sm="12">
                          <b-input-group :prepend="$t('inputs.time')">
                            <b-form-input
                              v-model="sub.time"
                              type="tel"
                              v-mask="'##:##:##'"
                              required
                            />
                          </b-input-group>
                        </b-col>

                        <b-col lg="2" sm="12">
                          <b-button
                            size="sm"
                            variant="danger"
                            @click="onRemoveSubtitle(sub)"
                          >
                            {{ $t('buttons.delete') }}
                          </b-button>
                        </b-col>
                      </b-row>

                      <hr class="hr-small" />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>

          <b-button
            :disabled="loading"
            class="mr-2"
            type="submit"
            variant="outline-primary"
            @click="cancel"
            >{{ $t('buttons.cancel') }}</b-button
          >
          <b-button :disabled="loading" type="submit" variant="success">{{
            $t('buttons.save')
          }}</b-button>
        </b-col>

        <b-col lg="6" sm="12">
          <!-- <iframe
            v-if="form.type === 'youtube'"
            class="iframe"
            type="text/html"
            width="160"
            height="90"
            :src="embed"
            frameborder="0"
          />
          <iframe
            v-if="form.type === 'vimeo'"
            class="iframe"
            :src="embed"
            width="160"
            height="90"
            frameborder="0"
            allowfullscreen
          ></iframe> -->
          <img :src="upload.preview || thumb" class="preview mb-1" />
        </b-col>
      </b-row>

      <h4 class="center text-info float-left text-uppercase mt-3">
        {{ $t('artistsManagement.videoYour') }}
      </h4>

      <b-table
        class="mt-3"
        show-empty
        responsive
        bordered
        striped
        hover
        :busy="loading"
        :items="model.videos"
        :fields="fields"
        :emptyText="$t('messages.emptyTable')"
        :emptyFilteredText="$t('messages.emptyFilteredTable')"
      >
        <template v-slot:table-busy>
          <div
            class="
              text-center text-danger
              my-2
              align-items-center
              d-flex
              flex-column
            "
          >
            <b-spinner
              class="align-middle flex-direction-column mb-3"
            ></b-spinner>
            <strong>{{ $t('messages.loading') }}...</strong>
          </div>
        </template>

        <template v-slot:cell(thumbnailPreview)="data">
          <img :src="data.item.thumbnailPreview" class="thumb" />
        </template>

        <template v-slot:cell(actions)="data">
          <p class="text-center m-0">
            <b-button
              size="sm"
              class="ml-1"
              :disabled="data.index == 0"
              variant="primary"
              @click="orderUp(data.item.id)"
            >
              <b-icon icon="caret-up-fill" aria-hidden="true"></b-icon>
            </b-button>
            <b-button
              size="sm"
              class="ml-1"
              :disabled="data.index == model.videos.length - 1"
              variant="primary"
              @click="orderDown(data.item.id)"
            >
              <b-icon icon="caret-down-fill" aria-hidden="true"></b-icon>
            </b-button>
            <b-button
              size="sm"
              class="ml-1"
              variant="outline-primary"
              @click="edit(data.item.id)"
            >
              <b-icon icon="pencil-fill" aria-hidden="true"></b-icon>
            </b-button>
            <b-button
              size="sm"
              class="ml-1"
              variant="danger"
              @click="deleteVideo(data.item.id)"
            >
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </b-button>

            <span class="mx-1" />
          </p>
        </template>
      </b-table>
    </form>

    <b-row v-else>
      <b-col cols="12">
        <h5 class="text-center mt-4">{{ $t('messages.loading') }}...</h5>
      </b-col>
    </b-row>

    <router-link
      :to="{ name: `events-stages-edit`, params: { id: model.id } }"
      class="float-right"
    >
      <b-button size="sm" class="ml-3" variant="info">
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          class="bi bi-backspace-fill mr-2"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241
              7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2
              2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147
              2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0
              1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976
              8 5.829 5.854z"
          />
        </svg>
        {{ $t('buttons.back') }}
      </b-button>
    </router-link>
  </div>
</template>

<script>
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { DateTime as Luxon } from 'luxon';
import EventsStage from '../../../../services/EventsStage';
import ImageUploader from '../../../../components/organisms/ImageUploader.vue';
import TextArea from '../../../../components/organisms/TextArea.vue';
import Toast from '../../../../assets/js/toast';
import EventsDates from '../../../../services/EventsDates';

export default {
  props: ['type'],
  components: {
    ImageUploader,
    TextArea,
  },
  data() {
    const data = {
      isEdit: false,
      loaded: false,
      loading: true,
      thumb: null,
      embed: '',
      upload: {
        file: null,
        preview: null,
        blob: null,
      },
      form: {
        type: 'link',
        subtitles: [],
      },

      model: {
        date: '',
      },

      languageOpts: [
        { text: 'English', value: 'en' },
        { text: 'Português', value: 'pt' },
      ],

      fields: [
        {
          key: 'date',
          label: 'Date',
          sortable: false,
        },
        {
          key: 'thumbnailPreview',
          label: '',
          sortable: false,
        },
        {
          key: 'nameEN',
          label: `${this.$t('inputs.titleEN')}`,
          sortable: false,
        },
        {
          key: 'namePT',
          label: `${this.$t('inputs.titlePT')}`,
          sortable: false,
        },
        { key: 'actions', label: this.$t('inputs.action') },
      ],

      daysOptions: [],
    };

    return data;
  },

  beforeMount() {
    this.onLoad();
  },

  methods: {
    onAddSubtitle() {
      const id = Math.floor(Math.random() * Date.now());
      this.form.subtitles.push({
        subtitle: '',
        time: '',
        id,
      });
      this.form = { ...this.form };
    },
    onRemoveSubtitle({ id }) {
      this.form.subtitles = this.form.subtitles.filter((obj) => obj.id !== id);
    },
    loadEmbed() {
      this.thumb = null;
      if (this.form.type === 'youtube') {
        this.embed = `https://www.youtube.com/embed/${this.form.videoId}`;
        this.thumb = `https://img.youtube.com/vi/${this.form.videoId}/0.jpg`;
      } else if (this.form.type === 'link') {
        this.embed = this.form.videoId;
      } else {
        this.embed = `https://player.vimeo.com/video/${this.form.videoId}`;
        Vue.http
          .jsonp(`//vimeo.com/api/v2/video/${this.form.videoId}.json`)
          .then((res) => res.json())
          .then((res) => {
            if (res && res.length > 0) {
              this.thumb = res[0].thumbnail_large;
            } else {
              this.thumb = null;
            }
          });
      }
    },
    getTooltip() {
      if (this.form.type === 'vimeo') return '<small>https://vimeo.com/</small><strong>76979871</strong>';
      if (this.form.type === 'youtube') return '<small>https://www.youtube.com/watch?v=</small><strong>eyGw85h8nGs</strong>';
      if (this.form.type === 'link') return '<small>https://www.site.com/video?=</small><strong>0000</strong>';
      return '';
    },
    cancel(e) {
      if (e) e.preventDefault();
      this.form.videoId = null;
      this.form.namePT = null;
      this.form.nameEN = null;
      this.form.language = null;
      this.form.descriptionPT = null;
      this.form.descriptionEN = null;
      this.form.id = null;
      this.form.subtitles = [];
      this.form.order = null;
      this.form.date = null;
      this.upload = {
        file: null,
        preview: null,
        blob: null,
      };
      this.thumb = null;
      this.embed = null;
    },
    async deleteVideo(id) {
      await EventsStage.deleteVideo(id, this.model.id);
      await this.onLoadUser();
    },
    async edit(id) {
      this.form = { ...this.model.videos.find((i) => i.id === id) };
      this.form.subtitles = this.form.subtitles || [];
      console.log('Form', this.form);
      console.log('days', this.daysOptions);

      const date = this.daysOptions.find((day) => day.text === this.form.date);
      this.form.date = date.value;
      this.loadEmbed();
      if (this.form.thumbnail) {
        this.upload.preview = this.form.thumbnailPreview;
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    async orderDown(id) {
      const ids = this.model.videos.map((i) => i.id);
      const idx = ids.indexOf(id);
      const buffer = ids[idx];
      ids[idx] = ids[idx + 1];
      ids[idx + 1] = buffer;

      await EventsStage.reorderVideo({ ids }, this.model.id);
      await this.onLoadUser();
    },
    async orderUp(id) {
      const ids = this.model.videos.map((i) => i.id);
      const idx = ids.indexOf(id);
      const buffer = ids[idx];
      ids[idx] = ids[idx - 1];
      ids[idx - 1] = buffer;

      await EventsStage.reorderVideo({ ids }, this.model.id);
      await this.onLoadUser();
    },
    async onSubmit() {
      try {
        this.loading = true;

        const fd = new FormData();
        let objPic;
        if (this.upload.file) {
          fd.append('file', this.upload.blob, this.upload.file.name);
          objPic = await EventsStage.uploadPicture(fd, this.model.id, 'video');
          this.form.thumbnail = objPic.filename;
        } else if (this.thumb) {
          this.form.thumbnail = this.thumb;
        }

        this.form.order = this.form.order
          || Math.max(...this.model.videos.map((i) => i.order), 1) + 1;
        if (!this.form.order) this.form.order = 1;
        const objPost = {
          ...this.form,
          dateId: this.form.date,
        };
        if (!this.form.id) await EventsStage.insertVideo(objPost, this.model.id);
        else {
          await EventsStage.updateVideo(objPost, this.model.id);
        }
        await this.onLoadUser();
        this.cancel();
        this.loading = false;
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('model', JSON.stringify(this.model, undefined, 2));
          scope.setExtra('form', JSON.stringify(this.form, undefined, 2));
          Sentry.captureException(e);
        });

        Toast.error(this, e);
        this.loading = false;
      }
    },
    async onLoad() {
      this.loading = true;
      const dates = await EventsDates.get();
      this.daysOptions = this.daysOpts(dates);

      await this.onLoadUser();

      this.loading = false;
      this.loaded = true;
    },

    async onLoadUser() {
      try {
        const { id } = this.$route.params;

        if (id) {
          this.isEdit = true;

          const user = await EventsStage.getVideos(id);

          this.model = {
            ...user,
            videos: user.videos
              .map((v) => ({
                ...v,
                datetime: Luxon.fromISO(v.date.date).toJSDate(),
                date: Luxon.fromISO(v.date.date).toFormat('dd/MM/yyyy'),
                thumbnailPreview:
                  v.thumbnail.indexOf('http') === 0
                    ? v.thumbnail
                    : `${Vue.prototype.VUE_APP_S3_URL}/stage/${user.id}/video/${v.thumbnail}`,
              }))
              .sort((a, b) => a.order - b.order),
          };
        }
      } catch (e) {
        Sentry.captureException(e);

        Toast.error(this, e);
        console.log('Falha ao carregar!', e);
      }
    },

    formatDate(date) {
      try {
        return Luxon.fromISO(date)
          .setLocale(this.$i18n.locale)
          .toLocaleString(Luxon.DATE_SHORT);
      } catch (e) {
        Toast.error(e);
        return date;
      }
    },

    daysOpts(dates) {
      return dates.map((date) => ({
        text: this.formatDate(date.date),
        value: date.id,
      }));
    },
  },
};
</script>

<style lang="sass" scoped>
.footer-buttons
  button
    min-width: 100px
.thumb
  max-height: 40px
.preview
  max-width: 100%
.iframe
  width: 100%
  height: 380px
.hr-small
  margin-top: 0.5rem
  margin-bottom: 0.5rem
</style>
